import { Link } from "gatsby"
import * as React from "react"
import { CommentCount } from "gatsby-plugin-disqus"
import { getDisqusConfig } from "../utils/Blog"
import { Author } from "./Author"
interface Props {
  post: any
  className?: string
}

const FeaturedPost = ({ post, className = "featured-post" }: Props) => {
  const disqusConfig = getDisqusConfig(post)
  return (
    <div className={`${className} mb-5`}>
      <article className={`blog-post py-6 px-6`}>
        <div className="newest mb-2">NEWEST POST</div>
        <h1 className="is-size-1 mb-3 has-text-weight-medium">
          <Link to={`/blog/${post.fields.path}`}>{post.title}</Link>
        </h1>
        <div className="meta">
          <Link to={`/blog/category/${post.category.toLowerCase()}`}>
            {post.category}
          </Link>
          {" | "}
          <Author author={post.author} />
          {` | `} {post.fields.publishedAt}
          {` | `}
          <Link to={`/blog/${post.fields.path}#disqus_thread`}>
            <CommentCount config={disqusConfig} placeholder={"..."} />
          </Link>
        </div>
        <div className="pt-4">
          <Link className="button is-spring" to={`/blog/${post.fields.path}`}>
            Read more
          </Link>
        </div>
      </article>
    </div>
  )
}

export default FeaturedPost
