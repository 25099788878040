import { graphql, Link } from "gatsby"
import React from "react"
import { getDisqusConfig } from "../utils/Blog"
import { CommentCount } from "gatsby-plugin-disqus"
import { Author } from "./Author"
import { HTMLContent } from "../common/Content"

interface Props {
  post: any
  isFirst?: boolean
  hideAuthor?: boolean
}

export const Post = ({ post, isFirst = false, hideAuthor = false }: Props) => {
  const disqusConfig = getDisqusConfig(post)
  return (
    <article className={`blog-post py-6 ${isFirst ? "is-first" : ""}`}>
      <h1 className="is-size-2 mb-5 has-text-weight-medium">
        <Link to={`/blog/${post.fields.path}`}>{post.title}</Link>
      </h1>
      <div className="meta">
        <Link to={`/blog/category/${post.category.toLowerCase()}`}>
          {post.category}
        </Link>
        {!hideAuthor && (
          <>
            {" | "}
            <Author author={post.author} />
          </>
        )}
        {` | `} {post.fields.publishedAt}
        {` | `}
        <Link to={`/blog/${post.fields.path}#disqus_thread`}>
          <CommentCount config={disqusConfig} placeholder={"..."} />
        </Link>
      </div>
      <div className="py-3">
        <HTMLContent
          content={post.body?.childMarkdownRemark?.excerpt}
          className={"content markdown"}
        ></HTMLContent>
      </div>
      <div className="pt-4">
        <Link className="button is-spring" to={`/blog/${post.fields.path}`}>
          Read more
        </Link>
      </div>
    </article>
  )
}

export const postFrontmatterFragment = graphql`
  fragment PostFragment on ContentfulBlogPost {
    id
    fields {
      path
      publishedAt(formatString: "MMMM DD, YYYY")
    }
    title
    author {
      id
      title
      inactive
      slug
    }
    category
    disqusId
    body {
      childMarkdownRemark {
        excerpt(format: HTML, pruneLength: 600)
      }
    }
  }
`
