import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import FeaturedPost from "../components/blog/FeaturedPost"
import Layout from "../components/layout/Layout"
import { Head } from "../components/blog/Head"
import { Post } from "../components/blog/Post"
import { Pagination } from "../components/blog/Pagination"
import Newsletter from "../components/forms/Newsletter"
import highlightCode from "../components/utils/HighlightCode"

// props
interface Props {
  data: {
    posts: {
      nodes: Array<{
        id: string
        fields: {
          slug: string
        }
        author: {
          id: string
        }
        body: string
      }>
    }
  }
  pageContext: {
    category: string
    limit: number
    skip: number
    numPages: number
    currentPage: number
    totalCount: number
  }
}
// markup
const BlogsTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode()
  })
  const posts = props.data.posts.nodes
  return (
    <Layout hideNewsletter={true} seo={{ title: "Blog" }} className="blog">
      <div className="container">
        <Head />
        {props.pageContext.currentPage < 2 && <FeaturedPost post={posts[0]} />}
        <div className="columns">
          <div className="column is-9 pr-6">
            {posts &&
              posts
                .slice(props.pageContext.currentPage < 2 ? 1 : 0)
                .map((post, index: number) => (
                  <Post
                    isFirst={index === 0}
                    key={`article-${index}`}
                    post={post}
                  />
                ))}
            <Pagination
              path={`/blog/`}
              currentPage={props.pageContext.currentPage}
              postsPerPage={props.pageContext.limit}
              totalCount={props.pageContext.totalCount}
            />
          </div>
          <div className="column is-3-desktop is-4" style={{ minWidth: 320 }}>
            <div className="has-background-light px-5 py-5 blog-sidebar">
              <h2 className="is-size-4 has-text-weight-bold pb-4">
                Get the Spring newsletter
              </h2>
              <Newsletter variant="blog" />
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default BlogsTemplate

// graphQL queries
export const pageQuery = graphql`
  query BlogsTemplate($skip: Int!, $limit: Int!) {
    posts: allContentfulBlogPost(
      sort: { order: DESC, fields: fields___publishedAt }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostFragment
      }
    }
    authors: allContentfulMember {
      nodes {
        title
        slug
        inactive
      }
    }
  }
`
